<template>
    <div class="cusModal" v-if="showtable">
        <div class="cusModalCont">
            <div class="cusModalTitle backBlack">{{ title }}
                <div class="eportBtn">
                    تصدير pdf | تصدير Excel | طباعة
                </div>
                <div class="cusModalClose" @click="closeTable()">{{ lang.close }}</div>
            </div>
            <div class="cusModalBody">
                <v-simple-table class="mt-1" :style="`padding-inline:40px;direction: ${lang.dir};`">
                    <thead>
                        <tr>
                            <th class="backBlack text-center">رقم الكرت</th>
                            <th class="backBlack text-center">نوع العميل</th>
                            <th class="backBlack text-center">اسم العميل</th>
                            <th class="backBlack text-center">رقم الاتصال</th>
                            <th class="backBlack text-center">الاجمالي</th>
                            <th class="backBlack text-center">{{lang.vat}}</th>
                            <th class="backBlack text-center">{{lang.ftotal}}</th>
                            <th class="backBlack text-center">{{lang.paid}}</th>
                            <th class="backBlack text-center">{{lang.remain}}</th>
                            <th class="backBlack text-center">{{ lang.status }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in tbrows" :key="index">
                            <td class="text-center">{{item.card_id}}</td>
                            <td class="text-center">{{item.customerType}}</td>
                            <td class="text-center">{{item.customer_name}}</td>
                            <td class="text-center">{{item.mobile}}</td>
                            <td class="text-center">{{item.total}}</td>
                            <td class="text-center">{{item.vat}}</td>
                            <td class="text-center">{{item.ftotal}}</td>
                            <td class="text-center">{{item.paid}}</td>
                            <td class="text-center">{{$RoundNums(parseFloat(item.ftotal) - parseFloat(item.paid),2)}}</td>
                            <td class="text-center">{{item._status}}</td>
                        </tr>
                    </tbody>
                    
                </v-simple-table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            title: 'اوامر التشغيل',
            showtable: false,
            tbrows: [],
            search: {
                sdate: '',
                edate: '',
            }
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        totals: function(){
            let t = {
                total: 0, qty: 0, current: 0
            }
            for(let i=0;i<this.tbrows.length;i++){
                t.qty = parseFloat(t.qty) + parseFloat(this.tbrows[i].qtty)
                t.total = parseFloat(t.total) + parseFloat(this.tbrows[i].total)
                t.current = parseFloat(t.current) + parseFloat(this.tbrows[i].current)
            }
            return t;
        },
    },
    methods: {
        closeTable(){
            this.title = 'اوامر التشغيل',
            this.showtable = false;
            this.tbrows = []
            this.search = {
                sdate: '',
                edate: '',
            }
        },
        getReport(option) {
            if(option.type == 1){
                this.title = "أوامر تشغيل تحت الاجراء"
            }
            if(option.type == 2){
                this.title = "أوامر تشغيل مغلقة"
            }
            if(option.type == 3){
                this.title = "أوامر تشغيل غير مفوتر"
            }
            if(option.type == 4){
                this.title = "أوامر تشغيل مفوتر"
            }
            if(option.paid != 0){
                this.title = "أوامر تشغيل قيد التحصيل"
            }
            this.showtable = true;
            const post = new FormData();
            post.append('type','getJobDashboard');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[options]',JSON.stringify(option));
           
            this.tbrows = [];
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
               this.tbrows = response.data.results.data;
            })
        }
    },
    created() {
        //
    },
}
</script>
<style scoped>
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.eportBtn{
    position: absolute;
    top:2px;
    left:60px;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
</style>

